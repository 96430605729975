import React from "react"
import styled from "styled-components"

// Styles
import GlobalStyles from "./home/components/global"

// Components
import VideoPlayer from "./home/components/big-video-site/videoPlayer"

const Launch = () => {
  return (
    <Container>
      <VideoPlayer />
      <GlobalStyles />
    </Container>
  )
}

export default Launch

const Container = styled.div`
  // resetting
  margin: 0 auto;
  padding: 0;
`
